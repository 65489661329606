import { configureStore } from "@reduxjs/toolkit";
// import additionalMiddleware from 'additional-middleware'
import { save, load } from "redux-localstorage-simple";
import { useDispatch } from "react-redux";
import homeReducer from './home'
import authReducer from './auth'

const PERSISTED_KEYS = ['home'];

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: {
        home: homeReducer,
        auth: authReducer
    },
    middleware: (getDefaultMiddleware) =>{
        return [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })]
    },
    preloadedState: load({ states: PERSISTED_KEYS }),
})


export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch();

export default store;
