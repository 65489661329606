import { createSlice } from '@reduxjs/toolkit'
import { RealDataEntity } from 'state/helpers'

export interface EntityState {
  cohortData: RealDataEntity
  currentPoolName: string
}

const initialState: EntityState = {
  cohortData: null,
  currentPoolName: 'Poseidon Pool',
}

export const HomeSlice = createSlice({
  name: 'Home',
  initialState,
  reducers: {
    setCohortData: (state, action) => {
      state.cohortData = { ...state.cohortData, ...action.payload }
      return state
    },
  },
})

export const { setCohortData } = HomeSlice.actions

export default HomeSlice.reducer
