import styled from "styled-components";
import { flexbox } from "styled-system";
import Box from "./Box";
import { FlexProps } from "./types";

const Flex = styled(Box)<FlexProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${flexbox}
`;
export const JustifyFlex = styled(Box)<FlexProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${flexbox}
`;

export default Flex;
