import React, { useState, lazy } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import styled from "styled-components";
import { ToastContainer } from 'react-toastify';
import useEagerConnect from 'hooks/useEagerConnect'
import 'react-toastify/dist/ReactToastify.css';
import Dots from 'components/Loader/Dots';

const Header = lazy(() => import('./components/common/Header'))
const Home = lazy(() => import('./views/Home'))
const Loading = () => <LoadingContainer><Dots style={{ marginLeft: 30 }}>Loading</Dots></LoadingContainer>;

const Routes = () => {
  const [isMenuOpen, setMenuOpen] = useState(false)
  useEagerConnect()
  return(
    <Router>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <React.Suspense fallback={<Loading />}>
        <Header setMenuOpen = {setMenuOpen} showModalVisible={isMenuOpen}/>
        <div className = {`site-outer-wrapper ${isMenuOpen ? "site-outer-wrapper-pulled" : ""}`}>
          <div className = "site-wrapper">
            <Route exact path = "/" component = {Home} />
          </div>
        </div>
      </React.Suspense>
    </Router>
  )
}

const LoadingContainer = styled.div`
  min-height: 100vh;
`;

export default Routes;