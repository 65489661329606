import BigNumber from 'bignumber.js/bignumber'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BASE_BSC_SCAN_URLS = {
  [1]: 'https://etherscan.io',
  [5]: 'https://goerli.etherscan.io',
  [42161]: 'https://arbiscan.io/',
  [421613]: 'https://goerli.arbiscan.io/',
}

export const BASE_URL = 'https://tribeone.io/'

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[1]

export const DEFAULT_CHAIN_ID = Number(process.env.REACT_APP_CHAIN_ID) ?? 5

export enum ConnectorNames {
  Injected = 'injected',
  WalletConnect = 'walletconnect',
  BSC = 'bsc',
}

export const SNAPSHOT_GRAPH_API = 'https://hub.snapshot.org/graphql'

// export const SUBGRAPH_ENDPOINT = 'https://api.thegraph.com/subgraphs/name/jhuliusanjelo/dev-tribeone-dao-voting'
export const SUBGRAPH_ENDPOINT =
  Number(process.env.REACT_APP_CHAIN_ID) === 1
    ? 'https://api.thegraph.com/subgraphs/name/jhuliusanjelo/main-tribeone-dao-voting'
    : 'https://api.thegraph.com/subgraphs/name/jhuliusanjelo/dev-tribeone-dao-voting'

export const LocalStorageForWalletConnection = 'LocalStorageForWalletConnection'

export const oneYearSeconds = 365 * 24 * 60 * 60
export const oneDaySeconds = 24 * 60 * 60

export const DepositActionByType = {
  '0': 'Vote Lock',
  '1': 'Create Lock',
  '2': 'Increased Lock Amount',
  '3': 'Increased Unlock Time',
}
