import React from 'react';
import BigNumber from 'bignumber.js';
import Routes from "./Routes";
import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function App() {
  return (
    <Routes />
  );
}

export default App;
