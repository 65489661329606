import React from 'react';
import Svg from '../Svg';
import { SvgProps } from '../types';

const Icon: React.FC<SvgProps> = (props) => {
	return (
		<Svg viewBox="0 0 15 15" {...props}>
			<g clipPath="url(#clip0_883_47029)">
				<path
					d="M7.5 0C3.35476 0 0 3.35438 0 7.5C0 11.6452 3.35438 15 7.5 15C11.6452 15 15 11.6456 15 7.5C15 3.35476 11.6456 0 7.5 0ZM7.5 13.9535C3.94154 13.9535 1.04651 11.0585 1.04651 7.5C1.04651 3.94151 3.94154 1.04651 7.5 1.04651C11.0585 1.04651 13.9535 3.94151 13.9535 7.5C13.9535 11.0585 11.0585 13.9535 7.5 13.9535Z"
					fill={props.color ?? "#59615F"}
				/>
				<path
					d="M7.27808 9.49023C6.86344 9.49023 6.52779 9.83576 6.52779 10.2504C6.52779 10.6552 6.85357 11.0106 7.27808 11.0106C7.70259 11.0106 8.03822 10.6552 8.03822 10.2504C8.03822 9.83576 7.69269 9.49023 7.27808 9.49023Z"
					fill={props.color ?? "#59615F"}
				/>
				<path
					d="M7.40639 3.73438C6.07362 3.73438 5.46155 4.52419 5.46155 5.05728C5.46155 5.44229 5.78733 5.62001 6.05387 5.62001C6.58699 5.62001 6.36981 4.85981 7.37677 4.85981C7.87037 4.85981 8.26529 5.07702 8.26529 5.53115C8.26529 6.06424 7.71243 6.37027 7.38665 6.64669C7.10033 6.89346 6.72521 7.29825 6.72521 8.14728C6.72521 8.66062 6.86343 8.80871 7.26817 8.80871C7.75189 8.80871 7.85062 8.59153 7.85062 8.40392C7.85062 7.89058 7.86049 7.59441 8.40348 7.1699C8.67002 6.9626 9.50915 6.29126 9.50915 5.36328C9.50915 4.4353 8.67002 3.73438 7.40639 3.73438Z"
					fill={props.color ?? "#59615F"}
				/>
			</g>
			<defs>
				<clipPath id="clip0_883_47029">
					<rect width="15" height="15" fill="white" />
				</clipPath>
			</defs>
		</Svg>
	);
};

export default Icon;
