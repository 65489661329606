import { useEffect } from 'react'
import useAuth from 'hooks/useAuth'
import { ConnectorNames } from 'config'
import { connectorLocalStorageKey } from 'utils/web3React'
import { useAppDispatch } from 'state'
// import { userLoginStatus } from 'state/auth'
import useActiveWeb3React from './useActiveWeb3React'
// import { userLogin } from 'state/helpers'

const _binanceChainListener = async () =>
  new Promise<void>((resolve) =>
    Object.defineProperty(window, 'BinanceChain', {
      get() {
        return this.bsc
      },
      set(bsc) {
        this.bsc = bsc

        resolve()
      },
    }),
  )

const useEagerConnect = () => {
  const { login, logout } = useAuth()
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()
  const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames

  useEffect(() => {
    const userInfoStorage = JSON.parse(window.localStorage.getItem("userInfo"))

    if (connectorId && userInfoStorage) {
      const isConnectorBinanceChain = connectorId === ConnectorNames.BSC
      const isBinanceChainDefined = Reflect.has(window, 'BinanceChain')

      if (isConnectorBinanceChain && !isBinanceChainDefined) {
        _binanceChainListener().then(() => login(connectorId))
        return
      }
      login(connectorId)
    } 
  }, [dispatch, login, account, logout, connectorId])
}

export default useEagerConnect
