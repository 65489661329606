import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 9 17" {...props}>
      <path d="M7.94727 15.5L0.947266 8.5L7.94727 1.5" stroke="#E7E9FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </Svg>
  );
};

export default Icon;